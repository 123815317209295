export enum TagStatus {
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export enum TagType {
  VillageClass = 'village_class',
  VillageComplete = 'village_complete',
  VillageType = 'village_type',
  VillageNear = 'village_near',
  Communications = 'communications',
  Heating = 'heating',
  Safety = 'safety',
  Infrastructure = 'infrastructure',
  Road = 'road',
  Transport = 'transport',
  PurposeLand = 'purpose_land',
  Highway = 'highway',
  District = 'district',
  HouseMaterial = 'house_material',
  InteriorDecoration = 'interior_decoration',
  Furniture = 'furniture'
}

export enum TagValue {
  Cottage = 'cottage',
  Townhouse = 'townhouse',
  Duplex = 'duplex',
  Flat = 'flat',
  Plot = 'plot'
}
